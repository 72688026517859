import { render, staticRenderFns } from "./CardAccordionComponent.vue?vue&type=template&id=074ab034&scoped=true"
import script from "./CardAccordionComponent.vue?vue&type=script&lang=js"
export * from "./CardAccordionComponent.vue?vue&type=script&lang=js"
import style0 from "./CardAccordionComponent.vue?vue&type=style&index=0&id=074ab034&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "074ab034",
  null
  
)

export default component.exports