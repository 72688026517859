<template>
  <div class="accordion col-12" role="tablist" id="accordions">
    <CardAccordionComponentVue 
      v-for="(faq, index) in faqs" :key="index"
      :id="index" :answer="faq.answer" :response="faq.response" :collapse="faq.collapse"/>
  </div>
</template>

<script>
import CardAccordionComponentVue from './CardAccordionComponent'
  export default {
    components: {
      CardAccordionComponentVue
    },
    data() {
      return {
        isVisibe: true,
        faqs: [],
      }
    },
    beforeMount() {
      const faqs = require('@/assets/config/faqs.json')
      this.faqs = faqs.faqs
    },
  }
</script>

<style lang="scss" scoped>
@import url('~@/assets/fonts.css');
  $yellow: #fbdf02;
  $gray: #453f41;
  $white: #FFFFFF;
  $shadow: rgba(0, 0, 0, 1);

.accordion{
    margin-top: 1em;
    padding: 0;
}
</style>