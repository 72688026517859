<template>
    <div class="card-comment">
        <div class="header">
            <div class="d-flex justify-content-between">
                <img src="@/assets/imgs/usercomment.svg" alt="Comentario" width="80px" loading="lazy">
                <img src="@/assets/imgs/comm.svg" alt="Comentario" width="80px" class="comm" loading="lazy">
            </div>
            <div>
                <p class="name" v-if="name">{{ name }}</p>
                <p class="name" v-else>Anónimo</p>
            </div>
        </div>

        <div class="body-message">
            <p class="message">
            {{ message }}
            </p>
        </div>

        <p class="date">
            {{ date }}
        </p>
    </div>
</template>
<script>
export default {
    props: {
        name: { type: [String, Boolean] },
        img: { type: String },
        message: { type: String },
        date: { type: String },
    }
}
</script>
<style lang="scss" scoped>
$yellow: #fbdf02;
$grey: #453f41;
    .card-comment {
        background-color: rgba(243, 244, 246, 1);
        padding: 2rem;
        border-radius: 10px;
        user-select: none !important;
        min-height: 400px;
        height: 400;

        :hover{
            cursor: pointer;
        }
    }

    .header {
        border-bottom: 1px solid $yellow;
    }

    .name {
        margin-top: 0.25rem;
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 600;
        --tw-text-opacity: 1;
        color: rgba(55, 65, 81, 1);
    }

    .body-message{
        min-height: 220px;
        height: 220px;
        .message {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            margin-top: 1rem;
            color: rgba(107, 114, 128, 1);
        }
    }

    .date{
        margin-bottom: 0;
        padding-top: 35px;
    }

    .comm{
        position: relative;
        top: -30px;
    }
</style>