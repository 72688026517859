<template>
    <div class="card" v-if="url" :style="{ backgroundImage: 'url(' + require(`@/assets/${url}`) + ')' }">
        <div class="card-info">
            <span class="name">{{ name }}</span>
            <p class="m-0 specialist">{{ specialist }}</p>
        </div>
    </div>
    <div class="card" :class=" {noTeam: url === '' } " v-else>
        <img src="@/assets/imgs/team/no-team.png" alt="">
        <div class="card-info">
            <span class="name">{{ name }}</span>
            <p class="m-0 specialist">{{ specialist }}</p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        name: { type: String, required: true },
        specialist: { type: String, required: true },
        url: { type: String }
    }
}
</script>
<style lang="scss" scoped>
@import url('~@/assets/fonts.css');
$yellow: #fbdf02;
$grey: #444242;

.card {
  width: 300px;
  height: 400px;
  display: flex;
  gap: 7px;
  border-radius: 10.5px;
  border: none;
  margin: 0 5px;
  margin-bottom: 0.7em;
  background-size: cover;
  background-position: center;
  background-color: $grey;

  &.noTeam{
    img{
      margin-top: 40px;

      @media (max-width: 600px) {
        width: 50%;
        margin: auto;
        margin-top: 40px;
      }
    }

    .card-info{
      bottom: 60px
    }
  }
  @media (max-width: 600px) {
    height: 275px;
  }
  .card-info{
      background: $yellow;
      border: none;
      padding: 0 15px;
      display: inline-block;
      text-transform: uppercase;
      cursor: pointer;
      transform: skew(-10deg);
      border-radius: 2px;
      text-shadow: none;
      color: rgba($color: $grey, $alpha: 1) !important;
      text-decoration: none;
      text-align: center;
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      width: 90%;
      margin-left: auto;
      margin-right: auto;

      .name{
        transform: skew(10deg);
        display: inline-block;
        font-family: 'robotoslab-bold';
        font-size: 18px;
      }

      .specialist{
        font-family: 'robotoslab-light';
        font-size: 18px;
        font-weight: 600;
      }
  }
}

</style>