import { render, staticRenderFns } from "./CommentCarouselComponent.vue?vue&type=template&id=661f073c&scoped=true"
import script from "./CommentCarouselComponent.vue?vue&type=script&lang=js"
export * from "./CommentCarouselComponent.vue?vue&type=script&lang=js"
import style0 from "./CommentCarouselComponent.vue?vue&type=style&index=0&id=661f073c&prod&lang=scss&scoped=true"
import style1 from "./CommentCarouselComponent.vue?vue&type=style&index=1&id=661f073c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661f073c",
  null
  
)

export default component.exports