<template>
    <div class="flip-card p-2" title="Toca para girar">
    <div class="flip-card-inner p-2" :class="{ 'flipped': !flipped }" @click="toggle()">
        <div class="flip-card-front p-2">
          <img :src="require(`@/assets/imgs/${image}`)" alt="Image" loading="lazy">
            <p class="title m-0">{{ title }}</p>
            <p class="subtitle m-0" v-for="(sub, index) in subtitle" :key="index"> {{ sub }}</p>
        </div>
        <div class="p-2 flip-card-back">
            <p class="description">{{ description }}</p>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    props: {
        title: { type: String, required: true},
        subtitle: { required: true },
        description: { type: String, required: true },
        image: { type: String, required: true },
    },
    data() {
      return {
        flipped : false,
      }
    },
    methods: {
      toggle(){
        this.flipped = !this.flipped
      }
    },
}
</script>
<style lang="scss" scoped>
  @import url('~@/assets/fonts.css');
  $yellow: #fbdf02;
  $grey: #453f41;
  $white: #FFFFFF;
  $shadow: rgba(0, 0, 0, 1);

  .flip-card {
    width: 250px;
    height: 275px;
    background-color: transparent;
    perspective: 1000px;
    border: none; 
    margin-left: 10px;
    margin-bottom: 15px;

    @media (max-width: 600px) {
      width: 100%;
      margin-left: 0px;
    }
    img{
      width: 175px;
      height: 175px;
      margin: auto;
    }
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-card .flip-card-inner {
    cursor: pointer;
    transform: rotateY(180deg);
  }

  .flip-card .flipped{
    transform: rotateX(0deg);
  }
  .flip-card-front, .flip-card-back {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid $yellow;
    border-radius: 5px;
  }


.flip-card-front{
  .title {
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    font-family: 'roboto-mediumitalic';
    text-transform: uppercase;
  }

  .subtitle{
    font-family: 'roboto-mediumitalic';
    font-style: normal;
    font-size: 14px;
  }
}
  .flip-card-back {
    font-family: 'roboto-light';
    font-size: 16px;
    transform: rotateY(180deg);
    margin-left: -15px;
    background-color: $grey;
    color: $white;
  }
</style>
