<template>
  <div class="init">
    <section class="col-12" id="services">
        <h4 class="title-section">Servicios</h4>
        <h5 class="subtitle-section">¿Qué ofrecemos?</h5>
        <div class="col-12 col-sm-8 m-auto d-flex justify-content-center flex-wrap">
          <CardServices v-for="(service, index) in services" :key="index"
            :title="service.title" :subtitle="service.subtitle" :description="service.description" :image="service.image"/>
        </div>

        <div class="col-12 text-center my-3">
          <a class="turn" href="https://wa.me/+5492215732268?text=Hola,%20quiero%20pedir%20un%20turno">Pedir turno</a>
        </div>
    </section>

    <section class="text-helper my-5">
      <p class="text-center">
        Como profesionales de la salud no tratamos lesiones, <br class="d-none d-sm-block">
        tratamos <b>personas</b> con una lesión, por ende nuestro enfoque <br class="d-none d-sm-block">
        va hacia cada persona individual y a sus objetivos específicos.</p>
    </section>

    <section id="professionals" class="mb-5">
      <h4 class="title-section">Profesionales</h4>
      <h5 class="subtitle-section">¿Quiénes forman parte del equipo?</h5>
      <div class="col-12 col-sm-8 m-auto d-flex justify-content-center flex-wrap">
        <CardTeamComponent v-for="(person, index) in team" :key="index"
              :name="person.name" :specialist="person.specialist" :url="person.url"/>
      </div>
    </section>

    <section id="packs" class="mb-5">
      <h4 class="title-section">PACKS</h4>
      <h5 class="subtitle-section">¿Se pueden combinar servicios?</h5>
      <h6 class="col-12 col-sm-6 m-auto mb-3 section-description">
        ¡Si! Complementando el trabajo de kinesiología con un orden nutricional <b>potenciamos</b> aún más
        los <b>resultados</b> y el <b>bienestar</b>, por eso es que lanzamos nuestros <b>packs de servicios</b>,
        en los cuales el paciente podrá contar con ambos asesoramientos al mismo tiempo y de manera 
        coordinada entre profesionales. Si lo necesitas, tambien podes sumar la confeccion de plantillas
      </h6>
      <div class="d-block d-sm-flex col-12 col-sm-6 m-auto justify-content-center packs">
        <div class="pack">
          <h4 class="title-section">Pack 1</h4>
          <div class="text-center">
            <p class="m-0">Kinesiología</p>
            <p class="m-0 plus">+</p>
            <p class="m-0">Nutrición</p>
          </div>
        </div>

        <div class="pack">
          <h4 class="title-section">Pack 2</h4>
          <div class="text-center">
            <p class="m-0">Kinesiología</p>
            <p class="m-0 plus">+</p>
            <p class="m-0">Nutrición</p>
            <p class="m-0 plus">+</p>
            <p class="m-0">Plantillas</p>
          </div>
        </div>
      </div>
      <div class="col-12 text-center my-5">
          <a class="turn" href="https://wa.me/+5492215732268?text=Hola,%20quiero%20información%20sobre%20packs">Quiero más info</a>
      </div>
    </section>

    <section id="os" class="mb-5 pb-4">
      <h4 class="title-section">Obras sociales</h4>
      <h5 class="subtitle-section">¿Qué cobertura trabajamos?</h5>
      <h6 class="text-center section-description">Estas son algunas de las obras sociales con las que trabajamos</h6>
      <div class="col-12 col-sm-8 m-auto text-center os">
        <p class="m-0">IOMA <span class="separator">|</span> OSPE <span class="separator">|</span> SANCOR SALUD</p>
        <p class="m-0">SWISS MEDICAL <span class="separator">|</span> OSPSA/ATSA <span class="separator">|</span> MEDIFÉ</p>
        <p class="m-0">CASA <span class="separator">|</span> Poder Judicial <span class="separator">|</span> OSECAC <span class="separator">|</span> OSSEG</p>
      </div>
      <div class="col-12 text-center my-5">
          <a class="turn" href="https://wa.me/+5492215732268?text=Hola,%20tengo%20una%20consulta%20sobre%20obras%20sociales">Consultá por la tuya</a>
      </div>
    </section>
   
   <section id="philosophy" class="philosophy mb-5">
      <div class="d-block d-sm-flex justify-content-between">
        <div class="col-sm-5 img-phi pl-0 d-none d-sm-block">
        </div>
        <div class="col-12 col-sm-6 phi-right">
          <h4 class="title-section text-left mt-5">Filosofía</h4>
          <h5 class="subtitle-section text-left">¿Qué nos caracteriza?</h5>
          <div class="text-phi col-12 col-sm-8 pl-0">
            <div class="mb-5">
              <p class="title">Nuestra misión</p>
              <p class="text">
                Acompañar y contener a nuestros pacientes, brindar las 
                herramientas necesarias para que puedan salir adelante. 
                Inspirar hábitos saludables que impacten de manera 
                positiva en su bienestar físico y en su calidad de vida.
              </p>
            </div>
            
            <div class="mb-5">
              <p class="title">Filosofía de trabajo </p>
              <p class="text">
                La ciencia y sus avances nos inspiran a una
                rehabilitación 100% personalizada y basada en el 
                movimiento, adecuada a cada objetivo en particular
                y a las necesidades de cada paciente.
              </p>
            </div>

            <div class="mb-5">
              <p class="title">Tratamiento de rehabilitación</p>
              <img class="ml-0 w-100" src="@/assets/imgs/tratamiento.png" alt="Linea tratamiento" loading="lazy">
            </div>
          </div>
        </div>
      </div>
      <CarouselComponent class="mt-2"/>
    </section>

    <section id="comments" class="comments pb-5">
      <h4 class="title-section">Reseñas</h4>
      <h5 class="subtitle-section">¿Qué opinan nuestros pacientes de nosotros?</h5>
      <div class="col-12 col-sm-6 m-auto">
        <CommentCarouselComponent/>
      </div>
    </section>

    <section id="faqs" class="faqs pt-5">
      <h5 class="subtitle-section">Preguntas frecuentes</h5>
      <div class="col-12 col-sm-8 m-auto">
        <AccordionComponent/>
        <h6 class="text-center mt-5 section-description">¿Tenés alguna consulta que no figure entre las preguntas?</h6>
        <div class="col-12 text-center mt-3 pb-5">
          <a class="turn" href="https://wa.me/+5492215732268?text=Hola,%20tengo%20una%20consulta">Consultanos ahora</a>
      </div>
      </div>
    </section>

    <section id="contact" class="contact mb-5 pt-5">
      <h5 class="subtitle-section">¡Contactanos!</h5>
        <div class="d-block d-sm-flex">
          <div class="col-12 col-sm-3 p-0">
            <p class="p-0 m-0 section-description">
              Estamos para ayudarte a mejorar tu calidad de vida, 
              en caso de querer tener un contacto directo y tener 
              más información, podés comunicarte con nosotros a 
              través de los siguientes canales:
            </p>

          </div>
          <div class="col-12 col-sm-9 right d-block d-sm-flex">
            <div class="col-12 col-sm-4 div-right">
              <a href="https://wa.me/+5492215732268?text=Hola,%20tengo%20una%20consulta" class="social-media d-block">
                  <img src="@/assets/imgs/wapp.svg" alt="" width="24px" loading="lazy">
                  <br>
                    221 573 2268
              </a>
              <a href="tel:+2214896752" class="social-media d-block mt-4">
                  <img src="@/assets/imgs/tel.svg" alt="" width="24px" loading="lazy">
                  <br>
                  221 489 6752
              </a>

              <div class="social-media mt-4">
                  <img src="@/assets/imgs/watch.svg" alt="" width="24px" loading="lazy">
                  <br>
                  Lunes a Viernes de 8 a 20 hs.
              </div>
              <div class="d-flex d-sm-block p-0 socialmedia">
                <a href="https://www.instagram.com/fixkinesiologia_" title="Instagram">
                  <div class="mt-8 fab-container">
                    <i class="fab fa-instagram"></i>
                  </div>
                </a>

                <a href="https://www.facebook.com/profile.php?id=100066551037144&mibextid=PtKPJ9" title="Facebook">
                  <div class="mt-5 fab-container">
                    <i class="fab fa-facebook-f"></i>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-12 col-sm-8 map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3272.2271392194266!2d-57.957873023491715!3d-34.90074897324715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a2e7aad0c5bd43%3A0xdeb764ad6f7ff190!2sFix%20Diagn%C3%B3stico%20y%20Kinesiolog%C3%ADa!5e0!3m2!1sen!2sar!4v1704859151385!5m2!1sen!2sar" width="100%" height="425" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <p class="direction"> <img src="@/assets/imgs/map.svg" alt="" width="24px" loading="lazy"> Plaza Alsina N°132 - e/ Diag. 74 y 1 | LA PLATA </p>
            </div>

          </div>
        </div>
      <div class="text-center fix">
        <img src="@/assets/imgs/fix.svg" alt="logo" width="10%" loading="lazy">
      </div>
      <div class="col-12 col-sm-9 m-auto text-center footer">
        <a href="#" class="mx-4" right v-scroll-to="'#services'">Servicios</a>
        <a href="#" class="mx-4" right v-scroll-to="'#professionals'">Profesionales</a>
        <a href="#" class="mx-4" right v-scroll-to="'#packs'">Packs</a>
        <a href="#" class="mx-4" right v-scroll-to="'#os'">Obras Sociales</a>
        <a href="#" class="mx-4" right v-scroll-to="'#philosophy'">Filosofía</a>
        <a href="#" class="mx-4" right v-scroll-to="'#contact'">Contacto</a>
      </div>
    </section>
  </div>
</template>

<script>
import CardServices from '@/components/CardServices'
import CardTeamComponent from '@/components/CardTeamComponent'
import CarouselComponent from '@/components/CarouselComponent'
import CommentCarouselComponent from '@/components/CommentCarouselComponent'
import AccordionComponent from '@/components/AccordionComponent'

export default {
  name: 'HomeView',
  components: {
    CardServices,
    CardTeamComponent,
    CarouselComponent,
    CommentCarouselComponent,
    AccordionComponent
  },
  data() {
    return {
      form: {
        email: '',
        name: '',
        comment: '',
      },
      team: [],
      services: [],
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    },
    onReset(event) {
      event.preventDefault()
      this.form.email = ''
      this.form.name = ''
      this.form.comment = ''
    }
  },
  beforeMount() {
    const teamJson = require('@/assets/config/team.json')
    this.team = teamJson.team
    const servicesJson = require('@/assets/config/services.json')
    this.services = servicesJson.team
  },
}
</script>

<style lang="scss" scoped>
  @import url('~@/assets/fonts.css');
  $yellow: #fbdf02;
  $gray: #453f41;
  $white: #FFFFFF;
  $shadow: rgba(0, 0, 0, 1);

  .init{
    margin-top: 3em;
    .socialmedia{
      gap: 40px;
      margin-bottom: 1em;
      a{
        color: #000;

        &:hover{
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
    .mt-8{
      margin-top: 4em;
      @media (max-width: 600px) {
        margin-top: 3rem !important;
      }
    }

    .title-section{
      font-size: 28px;
      font-weight: 600;
      color: $yellow;
      text-align: center;
      margin: auto;
      margin-bottom: 1em;
      text-transform: uppercase;
      font-family: 'robotolab-m';
    }

    .subtitle-section{
      font-size: 42px;
      font-weight: 400;
      color: $gray;
      text-align: center;
      margin: auto;
      margin-bottom: 1em;
      font-family: 'robotolab-m';
      @media (max-width: 600px) {
        font-size: 20px;
      }
    }

    .section-description{
      font-family: 'roboto-thin';
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: .012em;
      text-align: center;

      b{
        font-family: 'roboto-bold';
      }
    }

    .turn {
      background: $yellow;
      border: none;
      padding: 0 15px;
      display: inline-block;
      font-weight: 600;
      text-transform: uppercase;
      cursor: pointer;
      transform: skew(-10deg);
      border-radius: 2px;
      text-shadow: none;
      color: rgba($color: $gray, $alpha: 1) !important;
      text-decoration: none;
      font-size: 18px;
      font-family: robotoslab-regular;
    }

    .text-helper{
      padding: 30px 0;
      background-color: rgba($color: $gray, $alpha: .1) !important; ;
      font-family: 'robotolab-l';
    }

    #packs{
      padding-top: 4em !important;
      background-color: $gray;
      color: $white;
      padding-bottom: 2em !important;
      .title-section{
        font-size: 42px;
        text-transform: capitalize;
      }
      .subtitle-section{
        color: $white;
      }

      .packs{
        margin-top: 4em !important;
        .pack{
          border: 1px solid $white;
          border-radius: 10px;
          padding: 1em 20px;
          margin-left: 10px;
          font-family: 'roboto-mediumitalic';
          text-transform: uppercase;
          font-size: 32px;
          padding: 1em 4em;
          @media (max-width: 600px) {
            font-size: 24px;
            margin-left: 0px;
            height: 320px;
            &:nth-child(2){
              margin-top: 10px;
            }
          }
          .plus{
            color: $yellow;
          }
        }
      }
    }

    #os{
      .subtitle-section{
        margin-bottom: .5em;
      }
      h6{
        margin-bottom: 4.5em;

        @media (max-width: 600px) {
          margin-bottom: 3em;
        }
      }
      .os{
        p{
          font-size: 24px;
          font-family: 'roboto-m';
          @media (max-width: 600px) {
            font-size: 18px;
          }
        }
  
        .separator{
          color: $yellow;
        }
      }
    }

    .philosophy{
      background-color: rgba($color: $gray, $alpha: .1);

      @media (max-width: 600px) {
        .title-section{
          margin: 0;
          padding-top: 1em;
          margin-bottom: 1em;
        }
      }
      .img-phi{
        background-image: url('~@/assets/imgs/vertical.jpg');
        background-size: cover;
        background-repeat: no-repeat;
      }
      .phi-right{
        .title{
          font-size: 24px;
          font-family: 'roboto-bold';
        }

        .text{
          font-family: 'roboto-light';
          font-weight: 400;
          font-size: 20px;
          color: $gray;
        }
      }
    }

  .comments{
    background-color: $yellow;

    .title-section{
        color: $white;
        padding-top: 2.5em;
      }
      .subtitle-section{
        color: $gray;
      }
  }
  .faqs{
    background-color: rgba($color: $gray, $alpha: .1);
  }
  
  .contact{
    background-color: $gray;
    padding: 10em 3em 3em 3em;
    .section-description{
      text-align: left;
    }
    @media (max-width: 600px) {
      padding: 10em 1em 3em 1em;
    }
    .subtitle-section{
      color: $yellow;
      text-align: left;
    }
    p{
      color: $white;
      padding-right: 2em !important;
    }
    .right{
      border-left: 1px solid yellow;
      @media (max-width: 600px) {
        border-left: none;
        padding: 0;
        .div-right{
          padding: 0;
        }
      }

      .social-media{
        color: $white;
        font-size: 20px;
        font-family: 'robotolab-m';
        a{
          color: $white;
          text-decoration: none;
        }

        i{
          color: $yellow;
          font-size: 32px;
        }
      }

      .fab-container{
        width: 60px;
        height: 60px;
        background-color: $yellow;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .fa-instagram{
        font-size: 42px;
      }

      .fa-facebook-f{
        font-size: 42px;
      }
      .fa-map-marker-alt{
        color: $yellow;
      }
    }
    .footer{
      padding: 2em 0;
      border-top: 1px solid $white;
      border-bottom: 1px solid $white;
      margin-top: 2em !important;
      font-family: 'robotolab-m';
      text-transform: uppercase;
      a{
        color: $white;
        text-decoration: none;
        display: inline-flex;
        @media (max-width: 600px) {
          display: block;
        }
        &:hover{
          text-decoration: none;
        }
      }

    }
    .direction{
      font-family: 'robotolab-m' !important;
      margin-bottom: 0 !important;
      margin-top: 5px !important;
    }
  }

  .fix{
    margin-top: 8em;
    margin-bottom: 4em;

    @media (max-width: 600px) {
      margin-top: 4em;
      margin-bottom: 2em;
    }
  }

  @media (max-width: 600px) {
    .map{
      padding: 0;
    }
  }

}

</style>

<style lang="scss">
$yellow: #fbdf02;
$gray: #453f41;

.tns-ovh{
  overflow: hidden;
}
.tns-visually-hidden, button[data-action="stop"],button[data-action="start"] { display: none; }

.tns-nav{
    text-align: center;

    button{
        border-radius: 100%;
        height: 16px;
        width: 16px;
        border: none;
        margin-left: 2px;
        margin-right: 2px;
        margin-top: 2em;
        &.tns-nav-active{
          background-color: $gray;
        }
    }
}

</style>