<template>
  <div class="accordion col-12" role="tablist" id="accordions">
    <b-card no-body>
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle="'accordion-' + id" class="btn-accordion" @click="arrow">
            <div class="d-flex justify-content-between">
                <p class="text m-0">
                    {{ answer }}
                </p>
                <p class="chevron m-0 ml-auto">
                    <i class="fas fa-chevron-up" v-if="rotate"></i>
                    <i class="fas fa-chevron-down" v-else></i>
                </p>
            </div>
        </b-button>
      </b-card-header>
      <b-collapse :id="'accordion-' + id" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            {{ response }}
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
export default {
    props: {
        id: { required: true },
        answer: { type: String , required: true},
        response: { type: String , required: true},
        collapse: { type: Boolean, default: false },
    },
    data() {
        return {
            rotate: this.collapse
        }
    },
    methods: {
        arrow () {
            this.rotate = !this.rotate
        }
    },
}
</script>

<style lang="scss" scoped>
@import url('~@/assets/fonts.css');
  $yellow: #fbdf02;
  $gray: #453f41;
  $white: #FFFFFF;
  $shadow: rgba(0, 0, 0, 1);

.card{
    border: none !important;
    border-radius: 10px !important;
    margin-bottom: 1em;
    .card-header{
        background-color: $white;
        border: none;
            button {
            text-align: left;
            background-color: $white;
            border: none;
            color: $gray;
            font-size: 28px;
            font-weight: 400;
            font-family: 'robotolab-m';

            &:focus{
                box-shadow: none !important;
            }

            &:active{
                background-color: $white;
                border-color: $white;
                color: $gray;
            }
    }
      @media (max-width: 600px) {
        .text{
          font-size: 18px;
        }

        .chevron{
          font-size: 18px;
        }
      }
    }
}
</style>