<template>
    <tiny-slider 
      :mouseDrag="carousel.mouseDrag"
      :gutter="carousel.gutter"
      :autoInit="carousel.autoInit"
      :onInit="carousel.onInit"
      :responsive="carousel.responsive"
      :autoplay="carousel.autoplay"
      :controls="carousel.controls"
      :controlsContainer="carousel.controlsContainer"
      :navPosition="carousel.navPosition"
      :autoplayHoverPause="carousel.autoplayHoverPause"
      :navAsThumbnails="carousel.navAsThumbnails"
      :nav=false
      :speed="carousel.speed"
      :autoplayTimeout="carousel.autoplayTimeout"
      class="tns carousel-imgs"
      >
    >
      <div v-for="number in 16" :key="number" :style="{ backgroundImage: 'url(' + require('@/assets/imgs/carousel/'+ number +'.jpg') + ')' }" class="card-carousel">
      </div>
    </tiny-slider>
</template>

<script>
import VueTinySlider from 'vue-tiny-slider';

export default {
  components: {
    'tiny-slider': VueTinySlider
  },
  data() {
    return {
        carousel: {
          controls: false,
          controlsContainer: false,
          autoplayButtonOutput: false,
          navPosition	: 'bottom',
          autoplayHoverPause: false,
          autoplayTimeout: 2000,
          navAsThumbnails:  false,
          autoplay: true,
          autoInit: true,
          onInit: true,
          gutter: 20,
          mouseDrag: true,
          swipeAngle: 15,
          speed: 300,
          responsive: {
            320: {
              items: 1,
              slideBy: 1,
            },
            360: {
              items: 1.3,
              slideBy: 1,
            },
            480: {
              items: 1.4,
              slideBy: 1,
            },
            576: {
              items: 2,
              slideBy: 1,
            },
            768: {
              items: 3,
              slideBy: 1,
            },
            992: {
              items: 4,
              slideBy: 1,
            },
            1024: {
              items: 4,
              slideBy: 1,
            }
          }
        }
      }
  },
};
</script>

<style lang="scss" scoped>
  @import 'tiny-slider/src/tiny-slider';
  .card-carousel{
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
  }

</style>