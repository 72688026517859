<template>
    <tiny-slider 
        :mouseDrag="carousel.mouseDrag"
        :gutter="carousel.gutter"
        :autoInit="carousel.autoInit"
        :onInit="carousel.onInit"
        :responsive="carousel.responsive"
        :autoplay="carousel.autoplay"
        :controls="carousel.controls"
        :controlsContainer="carousel.controlsContainer"
        :navPosition="carousel.navPosition"
        :autoplayHoverPause="carousel.autoplayHoverPause"
        :navAsThumbnails="carousel.navAsThumbnails"
        :controlsText="carousel.controlsText"
    >
    <div v-for="(comment, index) in comments" :key="index">
        <CommentComponent :name="comment.name" :img="comment.img" :message="comment.message" :date="comment.date" class="comment"/>
    </div>
    </tiny-slider>
</template>

<script>
import VueTinySlider from 'vue-tiny-slider';
import CommentComponent from '@/components/CommentComponent.vue'

export default {
  components: {
    'tiny-slider': VueTinySlider,
    CommentComponent,
  },
  data() {
    return {
        comments: [],
        carousel: {
          controls: true,
          controlsText: [
            '<i class="fas fa-chevron-left"></i>',
            '<i class="fas fa-chevron-right"></i>'
          ],
          controlsContainer: false,
          autoplayButtonOutput:false,
          navPosition	: 'bottom',
          autoplayHoverPause: true,
          autoplay: true,
          autoInit: true,
          onInit: true,
          slideBy: 1,
          gutter: 20,
          mouseDrag: true,
          swipeAngle: 15,
          responsive: {
            320: {
              items: 1.3,
              slideBy: 1,
            },
            360: {
              items: 1.3,
              slideBy: 1,
            },
            480: {
              items: 1.3,
              slideBy: 1,
            },
            576: {
              items: 1.3,
              slideBy: 1,
            },
            768: {
              items: 2,
              slideBy: 1,
            },
            992: {
              items: 2,
              slideBy: 1,
            },
            1024: {
              items: 2,
              slideBy: 1,
            }
          }
        }
    }
  },
  beforeMount() {
    const commentsJson = require('@/assets/config/comments.json')
    this.comments = commentsJson.comments
  },
};
</script>

<style lang="scss" scoped>
  @import 'tiny-slider/src/tiny-slider';
  .comment{
    width: 100%;
  }

</style>

<style lang="scss">
.tns-nav button {
  @media (max-width: 600px) {
    width: 3px !important;
    height: 3px !important;
    border-radius: 0 !important;
  }
}
.tns-controls button{
    background: none;
    border: none;
    position: absolute;
    top: 45%;
    font-size: 32px;
    z-index: 999;
    &[data-controls="next"]{
      right: -50px;
    }

    &[data-controls="prev"]{
      left: -50px;
    }

    @media (max-width: 600px) {
      &[data-controls="next"]{
        // right: 0px;
        display: none;
      }

      &[data-controls="prev"]{
        // left: -5px;
        display: none;
      }
    }
  }
</style>