<template>
  <div id="app">
    <div>
      <b-navbar toggleable="lg" type="dark">
        <b-navbar-brand href="#">
          <img src="./assets/logo.png" alt="fix" width="128px" loading="lazy">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto right-nav">
            <b-nav-item class="mx-2 mx-xl-3 mx-xxl-4" right v-scroll-to="'#services'">Servicios</b-nav-item>
            <b-nav-item class="mx-2 mx-xl-3 mx-xxl-4" right v-scroll-to="'#professionals'">Profesionales</b-nav-item>
            <b-nav-item class="mx-2 mx-xl-3 mx-xxl-4" right v-scroll-to="'#packs'">Packs</b-nav-item>
            <b-nav-item class="mx-2 mx-xl-3 mx-xxl-4" right v-scroll-to="'#os'">Obras Sociales</b-nav-item>
            <b-nav-item class="mx-2 mx-xl-3 mx-xxl-4" right v-scroll-to="'#philosophy'">Filosofía</b-nav-item>
            <b-nav-item class="mx-2 mx-xl-3 mx-xxl-4" right v-scroll-to="'#contact'">Contacto</b-nav-item>
            <b-nav-item class="mx-2 mx-xl-3 mx-xxl-4 turn" right href="https://wa.me/+5492215732268?text=Hola,%20quiero%20pedir%20un%20turno">Pedir turno</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      
      <b-spinner label="Spinning" class="spinner" v-if="loading"></b-spinner>
      <video ref="video" autoplay muted loop preload="auto">
        <source src="./assets/imgs/1.mp4" type="video/mp4">
      </video>
    </div>

    <div>
      <router-view/>
    </div>

  </div>

  
</template>
<script>
export default {
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    this.checkAndPlayVideo();
  },
  methods: {
    checkAndPlayVideo() {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const videoElement = this.$refs.video;

      if (isMobile) {
        videoElement.setAttribute('playsinline', '');
        videoElement.play();
      }
      videoElement.addEventListener('loadeddata', () => {
        this.loading = false;
      });
      if (isMobile) {
        const videoContainer = this.$refs.videoContainer;
        videoContainer.style.pointerEvents = 'none';
      }
    },
  },
};
</script>
<style lang="scss">
  @import url('~@/assets/fonts.css');
  $yellow: #fbdf02;
  $gray: #453f41;
  $white: #FFFFFF;
  $shadow: rgba(0, 0, 0, 1);


html{
  background-color: $gray;
}
#app{
  .spinner{
    position: absolute;
    margin-left: 50%;
    margin-right: 50%;
    margin-top: 20%;
    color: $yellow;
  }
  @media (max-width: 600px) {
    .navbar-collapse{
      background-color: rgba($color: $gray, $alpha: .6) !important;
      padding-bottom: 1em;
      border-radius: 5px;
    }
  }
  .navbar-toggler{
      border: none !important;
      border-color: none !important;

      .navbar-toggler-icon{
        width: 1em !important;
        height: 1em !important;
        background-image: url('~@/assets/imgs/navbar.svg');
      }
  }
  .navbar{
    background: transparent !important;
    position: absolute;
    width: 100%;
    z-index: 999;
    .right-nav{
      margin-right: 5em;
    }
    .navbar-brand{
      img{
        width: 128px;
        @media (max-width: 600px) {
          width: 40px;
        }
      }
    }

    .nav-item{
      font-size: 16px;
      text-shadow: 4px 4px 8px $shadow;
      font-family: 'robotolab-m';
      text-transform: uppercase;
      a{
        color: rgba($color: $white, $alpha: 0.9) !important;
      }
    }

    .turn {
      background: $yellow;
      border: none;
      padding: 0 10px;
      display: inline-block;
      font-weight: 600;
      text-transform: uppercase;
      cursor: pointer;
      transform: skew(-18deg);
      border-radius: 2px;
      text-shadow: none;
      @media (max-width: 600px) {
        width: fit-content;
        margin-top: 10px;
      }
      a {
        display: inline-block;
        transform: skew(18deg);
        color: rgba($color: $gray, $alpha: 1) !important;
      }
    }
  }
  video {
    min-height: 75%;
    max-height: 75%;
    min-width: 100%;
    max-width: 100%;
    border-bottom: 20px solid $yellow;
  }
}
</style>
